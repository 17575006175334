<template>
  <!-- Main content -->
  <TopNav></TopNav>
  <router-view></router-view>
</template>

<script>
import emitter from '@/components/tools/emitter';
import TopNav from '@/components/front/TopNav.vue';

export default {
  name: 'CityLayout',
  data() {
    return {
      slug: '',
      routePath: '',
      city: [
        {
          City: '',
          SchoolName: '',
          SchoolDescriptionEng: '',
          SchoolDescriptionChin: '',
          Phyxhibition: false,
        },
      ],
      currentCity: '',
      currentHour: null,
      pageLoadStatus: false,
    };
  },
  components: {
    TopNav,
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.name': function (data) {
      console.log('route change', data, 'pageload status', this.pageLoadStatus);
      emitter.emit('time-city-load', this.pageLoadStatus);
    },
    currentCity(n, o) {
      // console.log(n, o);
      if (this.currentHour && this.currentCity) {
        this.pageLoadStatus = true;
      }
      this.removeBodyColor(o);
      if (this.currentHour) {
        this.bodyColor();
      }
    },
    pageLoadStatus() {
      if (this.pageLoadStatus) {
        emitter.emit('time-city-load', this.pageLoadStatus);
        this.bodyColor();
      }
    },
  },
  methods: {
    getCurrentHour(data) {
      console.log('received converted hours', data);
    },
    bodyColor() {
      // console.log('update body color');
      const body = document.getElementsByTagName('body')[0];
      if (this.currentHour > 6 && this.currentHour < 18) {
        this.$store.commit('setTime', 'day');
      } else {
        this.$store.commit('setTime', 'night');
      }
      switch (this.currentCity) {
        case 'Hong Kong':
          if (this.currentHour > 6 && this.currentHour < 18) {
            body.classList.add('bg-hongkong-day');
            emitter.emit('emit-day-night', 'day');
          } else {
            body.classList.add('bg-hongkong-night');
            emitter.emit('emit-day-night', 'night');
          }
          break;
        case 'Macao':
          if (this.currentHour > 6 && this.currentHour < 18) {
            body.classList.add('bg-macau-day');
            emitter.emit('emit-day-night', 'day');
          } else {
            body.classList.add('bg-macau-night');
            emitter.emit('emit-day-night', 'night');
          }
          break;
        case 'Beijing':
          if (this.currentHour > 6 && this.currentHour < 18) {
            body.classList.add('bg-beijing-day');
            emitter.emit('emit-day-night', 'day');
          } else {
            body.classList.add('bg-beijing-night');
            emitter.emit('emit-day-night', 'night');
          }
          break;
        case 'San Francisco':
          if (this.currentHour > 6 && this.currentHour < 18) {
            body.classList.add('bg-sf-day');
            emitter.emit('emit-day-night', 'day');
          } else {
            body.classList.add('bg-sf-night');
            emitter.emit('emit-day-night', 'night');
          }
          break;
        case 'Tallinn':
          if (this.currentHour > 6 && this.currentHour < 18) {
            body.classList.add('bg-tallinn-day');
            emitter.emit('emit-day-night', 'day');
          } else {
            body.classList.add('bg-tallinn-night');
            emitter.emit('emit-day-night', 'night');
          }
          break;
        case 'Rome':
          if (this.currentHour > 6 && this.currentHour < 18) {
            body.classList.add('bg-rome-day');
            emitter.emit('emit-day-night', 'day');
          } else {
            body.classList.add('bg-rome-night');
            emitter.emit('emit-day-night', 'night');
          }
          break;
        default:
          if (this.currentHour > 6 && this.currentHour < 18) {
            body.classList.add('bg-citya-day');
            emitter.emit('emit-day-night', 'day');
          } else {
            body.classList.add('bg-citya-night');
            emitter.emit('emit-day-night', 'night');
          }
          break;
      }
    },
    removeBodyColor(lastCity) {
      const body = document.getElementsByTagName('body')[0];
      switch (lastCity) {
        case 'Hong Kong':
          if (this.currentHour > 6 && this.currentHour < 18) {
            body.classList.remove('bg-hongkong-day');
          } else {
            body.classList.remove('bg-hongkong-night');
          }
          break;
        case 'Macao':
          if (this.currentHour > 6 && this.currentHour < 18) {
            body.classList.remove('bg-macau-day');
          } else {
            body.classList.remove('bg-macau-night');
          }
          break;
        case 'Beijing':
          if (this.currentHour > 6 && this.currentHour < 18) {
            body.classList.remove('bg-beijing-day');
          } else {
            body.classList.remove('bg-beijing-night');
          }
          break;
        case 'San Francisco':
          if (this.currentHour > 6 && this.currentHour < 18) {
            body.classList.remove('bg-sf-day');
          } else {
            body.classList.remove('bg-sf-night');
          }
          break;
        case 'Tallinn':
          if (this.currentHour > 6 && this.currentHour < 18) {
            body.classList.remove('bg-tallinn-day');
          } else {
            body.classList.remove('bg-tallinn-night');
          }
          break;
        case 'Rome':
          if (this.currentHour > 6 && this.currentHour < 18) {
            body.classList.remove('bg-rome-day');
          } else {
            body.classList.remove('bg-rome-night');
          }
          break;
        default:
          if (this.currentHour > 6 && this.currentHour < 18) {
            body.classList.remove('bg-citya-day');
          } else {
            body.classList.remove('bg-citya-night');
          }
          break;
      }
    },
  },
  mounted() {
    // console.clear();
    emitter.on('emit-hour', (data) => {
      this.currentHour = data;
      if (this.currentHour && this.currentCity !== '') {
        this.pageLoadStatus = true;
      }
    });
    emitter.on('emit-city', (data) => {
      this.currentCity = data;
    });
    emitter.on('city-route-change', () => {
      emitter.emit('time-city-load', this.pageLoadStatus);
    });
  },
  unmounted() {
    this.removeBodyColor(this.currentCity);
  },
};
</script>
